import axios from "@/axios.js";
/* eslint-disable */
export default {
  caseSearch(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/case-search`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetCaseSearch(obj) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw?type=statement` + obj)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetJurisdiction(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  SummarizeCase(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/case-search/summarize-jurisdiction/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  OpenSummary(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/jurisdiction/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendQuestion(type, id, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/case/${type}/${id}/ask`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
